import Apiv3 from './apiv3'

export const count = async () => {
  const resp = await Apiv3.get('/cart')
  return resp
}

export const addCart = async (item) => {
  const resp = await Apiv3.post('/cart', item)
  return resp
}

export const getCart = async () => {
  const resp = await Apiv3.get('/cart')
  return resp
}

export const getPaymentsMethods = async (loja_id) => {
  const resp = await Apiv3.get('/cart/payment_methods', { params: { loja_id } })
  return resp
}
