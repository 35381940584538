import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import 'dynamic-import-polyfill';
import App from './App'

import { HelmetProvider } from 'react-helmet-async'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
