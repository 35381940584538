import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import CircularLoading from '../../components/ScreenLoading/CircularLoading'
import { getPageWidgets } from '../../services/widgets/getPageWidgets'
import UserContext from '../User'
import Apiv3 from '../../services/api/apiv3'
import ShopContext from '../Shop'

export const WidgetContext = createContext()

const exceptRoutes = [
  '/p',
  '/produto',
  '/product',
  '/busca',
  '/search',
  '/carrinho',
  '/cart',
  '/checkout',
  '/conta',
  '/compras',
  '/pedidos',
  '/pedido',
  '/conta',
  '/categorias',
  '/categoria',
  '/category',
  '/categorys',
  '/login',
  '/entrar',
  '/registro',
  '/register',
  '/confirmacao-email',
  '/redefinir-senha',
  '/404',
  '/email-confirmation',
  '/account-created',
  '/termos',
  '/catalogo',
  '/catalogos',
  '/ofertas',
  '/cadastro-realizado-com-sucesso',
  '/email-ja-cadastro',
  '/admin',
  '/administrativo',
]

export const WidgetContextProvider = ({ children }) => {
  const user = useContext(UserContext)
  const shop = useContext(ShopContext)
  const location = useLocation()
  const [widgets, setWidgets] = useState([])
  const [page, setPage] = useState()
  const [visibility, setVisibility] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchWidgets = async () => {
    try {
      setLoading(true)
      const data = await getPageWidgets(shop.id, location.pathname)
      setWidgets(data?.data?.page?.widgets || [])
      setPage(data?.data?.page)
      setVisibility(data?.data?.page?.visibility)
      setLoading(false)
    } catch (error) {
      window.location.href = window.location.origin + '/404'
    }
  }

  const firstPath = location.pathname.split('/')

  useEffect(() => {
    if (exceptRoutes.includes(`/${firstPath[1]}`)) {
      setLoading(false)
      return
    } else {
      fetchWidgets()
    }
  }, [location.pathname])

  if (loading || user?.loading) return <CircularLoading />
  if (!loading && !user?.loading && visibility === 'private' && !user?.usuario) {
    window.location.href = window.location.origin + '/404'
  } else {
  }
  return <WidgetContext.Provider value={{ widgets, page }}>{children}</WidgetContext.Provider>
}

export const useWidget = () => useContext(WidgetContext)
