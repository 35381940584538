import { createTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'

const defaultTheme = createTheme(
  {
    typography: {
      fontFamily: [
        "'33535gillsansmt'",
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      background: {
        default: '#F8F8F8',
        primary: '#FFFFFF',
        secondary: '#F0F0F0',
        success: '#00cc99',
      },
      primary: {
        light: '#906baa',
        main: '#744493',
        dark: '#513168',
        contrastText: '#fff',
      },
      secondary: {
        light: '#fbc867',
        main: '#FABB41',
        dark: '#af822d',
        contrastText: '#FFF',
      },
      success: {
        light: '#33d6ad',
        main: '#00cc99',
        dark: '#008e6b',
        contrastText: '#FFF',
      },
      warning: {
        light: '#fbc867',
        main: '#FABB41',
        dark: '#af822d',
        contrastText: '#FFF',
      },
      error: {
        light: '#f73378',
        main: '#f44336',
        dark: '#ab003c',
        contrastText: '#FFF',
      },
      text: {
        primary: '#404040',
        secondary: '#989898',
        dark: '#000000',
        option: '#D3D3D3',
      },
      header: {
        text: "#754795", 
        primary: "#FFFFFF", 
        secondary: "#754795", 
        textSencondary: "#FABB41"
      }, 
      credits: {
        text: "#FFFFFF", 
        background: "#00cc99"
      },
      footer: {
        link: "#FABB41", 
        text: "#ffffff", 
        background: "#754795"
      }
    },
    overrides: {
      MuiButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiButtonBase: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          a: {
            outline: 'none',
          },
        },
      },
    },
  },
  ptBR
)

export default defaultTheme
