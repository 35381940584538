import Api from '../services/api/api';
let cardBrand = null;
let installments = null;
let cardToken = null;
let senderHash = null;

export default class PagSeguro{

    constructor(){
        this.session_id = null;
        this.sentSession = false;
        this.cardToken = null;
        this.cardBrand = null;
        this.senderHash = null;
        this.installments = null;
        this.creditCard = null; 

        this._subscribers = [];

        this.PagSeguroDirectPayment = window.PagSeguroDirectPayment;

    }

    setCreditCard = async (cc) => {
        let installmentAmount = this.installments.filter( item => {
            return item.quantity == cc.installment;
        })
        this.creditCard = {...cc, 
                            brand: this.cardBrand, 
                            cardToken : this.cardToken, 
                            senderHash : this.senderHash, 
                            installmentAmount : installmentAmount[0].installmentAmount
                         };
        this.notify();
        await this.createCardToken(this.creditCard);
    }



    subscribe(func) {
        this._subscribers.push(func);
    }

    unsubscribe(func) {
        this._subscribers = this._subscribers.filter(f => f !== func);
    }

    notify() {
        const data = { 
            session_id: this.session_id, 
            sentSession: this.sentSession, 
            creditCard : this.creditCard,
            cardToken: this.cardToken, 
            cardBrand: this.cardBrand, 
            senderHash: this.senderHash, 
            installments: this.installments, 
        };
        this._subscribers.forEach(func => func(data));
    }
    
    createSession = async () => {
        Api.get('/pagseguro')
        .then(resp => {
            this.session_id = resp.data.id;
            this.sendSession();
            console.log("Sessão criada");
        })
        .catch(error => {
            if (error.response) {
                // Request made and server responded
                console.warn(error.response.data);
                console.warn(error.response.status);
                console.warn(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.warn(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.warn('Error', error.message);
              }
        })
        /* try {
            const resp = await Api.get('/pagseguro');
            this.session_id = resp.data.id;
        } catch (error) {
            if (error.response) {
                // Request made and server responded
                console.warn(error.response.data);
                console.warn(error.response.status);
                console.warn(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.warn(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.warn('Error', error.message);
              }
        }  */    
    }

    sendSession = () => {
        window.PagSeguroDirectPayment.setSessionId(this.session_id);
        this.sentSession = true;

    }

    createCardToken = async (card) => {
        if(
            card.number &&
            card.brand &&
            card.cvv &&
            card.validate
        ){
            await this.PagSeguroDirectPayment.createCardToken({
                cardNumber: card.number, // Número do cartão de crédito
                brand: card.brand, // Bandeira do cartão
                cvv: card.cvv, // CVV do cartão
                expirationMonth: card.validate.substring(0,2), // Mês da expiração do cartão
                expirationYear: '20' +  card.validate.substring(2), // Ano da expiração do cartão, é necessário os 4 dígitos.
                success: function(response) {
                    cardToken = response.card.token;
                },
                complete: function(response) {
                    cardToken = response.card.token;
               },
             });

            if(!cardToken){
                setTimeout( () => this.createCardToken(card) ,1700);
                return;
            }

            this.cardToken = cardToken;
            this.notify();
        }
        
    }

    senderHashReady = () => {
        this.senderHash = this.PagSeguroDirectPayment.dnahash;
        this.PagSeguroDirectPayment.onSenderHashReady(function(response){
            if(response){
                if(response.status == 'error') {
                    console.log('error', response.message);
                    return false;
                }
                senderHash = response.senderHash; //Hash estará disponível nesta variável.
                this.senderHash = senderHash;
                this.notify();
            } 
        });

        /* if(!senderHash){
            setTimeout( () => this.senderHashReady() ,1700);
            return;
        } */

    }

    getBrandInstallments = async (cardBin,amount, maxInstallmentNoInterest = 2) => {
        if(cardBin){
            await this.PagSeguroDirectPayment.getBrand({
                cardBin: cardBin,
                async: true,
                success: function(response) {
                    cardBrand = response.brand.name;
                }
            });
    
            if(!cardBrand){
                setTimeout( () => this.getBrandInstallments(cardBin, amount, maxInstallmentNoInterest ) ,1500);
                return;
            }

            this.cardBrand = cardBrand;

            this.getInstallments(amount, cardBrand, maxInstallmentNoInterest );
        }
    }

    getInstallments = (amount, brand = null, maxInstallmentNoInterest = 2) => {
        let _brand = brand || this.cardBrand;
        if(amount > 0){
            this.PagSeguroDirectPayment.getInstallments({
                amount: amount,
                maxInstallmentNoInterest: maxInstallmentNoInterest,
                brand: _brand || 'visa',
                complete: this.setInstallments,
                /*success: function(response){
                    installments = _brand ? response.installments[_brand] : response.installments['visa'];
                    callback(response);
                },*/
                /*complete: (data) => {
                    //installments = _brand ? data.installments[_brand] : data.installments['visa'];
                    this.setInstallments(_brand ? data.installments[_brand] : data.installments['visa']);
                },*/
            });
            /*if(!installments){
                setTimeout( () => this.getInstallments(amount,_brand, maxInstallmentNoInterest ) ,1500);
                return;
            }*/
            //this.installments = installments;
            //this.notify();
        }
        
    }

    setInstallments = (data) => {
        if(data.installments){
            this.installments = data.installments[this.cardBrand || 'visa'];
            this.notify();
        }
    }

    getBrand = (cardBin) => {
        if(cardBin){
            this.PagSeguroDirectPayment.getBrand({
                cardBin: cardBin,
                async: true,
                success: function(response) {
                    cardBrand = response.brand.name;
                }
            });
    
            if(!cardBrand){
                setTimeout( () => this.getBrand(cardBin) ,1500);
                return;
            }
            this.cardBrand = cardBrand;
            this.notify();
        }
        
    }

    creditCardPay = async () => {
        if(this.senderHash === null || this.cardToken === null) {
            return false
        }

        try {
            const resp = await Api.post('/pagseguro', {senderHash : this.senderHash, cardToken :  this.cardToken });
            return resp.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }   
}   