import Api from './api.js'

export const getContacts = async (id) => {
  const resp = await Api.get('/entidade-loja', { id })
  return resp
}

export const getShop = async () => {
  const resp = await Api.get('shop')
  return resp
}

export const getShopAndMount = async () => {
  const resp = await Api.get('shop')
  if (resp?.data) {
    const data = resp.data
    data.site = {
      link: data.link,
      logo: data.logo,
    }
    data.endereco = {
      logradouro: data.logradouro,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      municipio: data.municipio,
      uf: data.uf,
      cep: data.cep,
    }
    data.contatos = {
      phones: data.telefones,
      cells: data.celulares,
      emails: data.emails,
    }
    data.slides = data.slide
    return data
  }
  return resp
}

export const getSlides = async () => {
  const resp = await Api.get('shop/slides')
  return resp
}

export const getCategorys = async () => {
  const resp = await Api.get('shop/categorys')
  return resp
}
