import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

const CircularLoading = () => {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      </Grid>
    </div>
  )
}

export default CircularLoading
