import Api from '../services/api/api';

export default class Category{
    constructor(){
        this.name = [];
        this.email = null;
        this._subscribers = [];
    }

    subscribe(func){
        this._subscribers.push(func);
    }

    unsubscribe(func){
        this._subscribers = this._subscribers.filter(f => f !== func);
    }

    notify(){
        const data = { name : this.name, email : this.email };
        this._subscribers.forEach(func => func(data));
    }

    async store({name, email}){
        try {
            const resp = await Api.post(`/lead`, {name, email});
            this.name = resp.data.name;
            this.email = resp.data.email;
            this.notify();
        } catch (error) {
            throw(error);
        }
    }
}